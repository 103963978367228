import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import Swal from 'sweetalert2'
import vSelect from "vue-select"
import datePicker from 'vuejs-datepicker'
import "vue-select/dist/vue-select.css"
import VueTheMask from 'vue-the-mask'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import HighchartsMapModule from 'highcharts/modules/map'
import mapData from '@highcharts/map-collection/countries/br/br-all.topo.json'
import panZoom from 'vue-panzoom'
import autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import jQuery from "jquery"
global.jQuery = jQuery
global.$ = jQuery

Vue.config.productionTip = false

Vue.prototype.$axios = Axios
Vue.prototype.$toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 3500,
   timerProgressBar: true,
   didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
   }
})

Vue.directive('focus', {
   inserted: function (el) {
      el.focus()
   }
})

HighchartsMapModule(Highcharts)
Highcharts.maps['mapaBrasil'] = mapData

Vue.use(panZoom)
Vue.use(VueTheMask)
Vue.use(autocomplete)
Vue.use(HighchartsVue)
Vue.component(Cropper)
Vue.component("v-select", vSelect)
Vue.component("datePicker", datePicker)

require("@/assets/cliente/css/preloader.css")
require("@/assets/cliente/css/bootstrap.min.css")
require("@/assets/cliente/css/slick.css")
require("@/assets/cliente/css/meanmenu.css")
require("@/assets/cliente/css/nice-select.css")
require("@/assets/cliente/css/owl.carousel.min.css")
require("@/assets/cliente/css/animate.min.css")
require("@/assets/cliente/css/fontAwesome5Pro.css")
require("@/assets/cliente/css/default.css")
require("@/assets/cliente/css/style.css")
require("@/assets/cliente/js/vendor/jquery-3.6.0.min.js")
require("@/assets/cliente/js/vendor/waypoints.min.js")
require("@/assets/cliente/js/bootstrap.bundle.min.js")
require("@/assets/cliente/js/meanmenu.js")
require("@/assets/cliente/js/slick.min.js")
require("@/assets/cliente/js/nice-select.min.js")
require("@/assets/cliente/js/owl.carousel.min.js")

require('@/assets/admin/fonts/overpass/stylesheet.css')
require('@/assets/admin/css/app.css')
require('@/assets/admin/css/header-colors.css')
require('@/assets/admin/css/icons.css')

new Vue({
   router,
   store,
   render: h => h(App)
}).$mount('#app')
