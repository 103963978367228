<template>
	<footer>
		<div class="footer__area footer-bg-2">
			<div class="footer__top pt-50">
				<div class="container custom-container">
					<div class="row text-center">
						<!-- Logo -->
						<div class="col d-none d-xl-block">
							<div class="footer__widget">
								<div class="footer__widget-title mb-45">
									<div class="footer__logo">
										<router-link to="/">
											<img src="@/assets/cliente/img/logo/logo-white.png" alt="logo">
										</router-link>
									</div>
								</div>
							</div>
						</div>

						<!-- SAC -->
						<div class="col align-self-center align-self-sm-start">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<router-link to="/" class="d-block d-xl-none">
										<img src="@/assets/cliente/img/logo/logo-white.png" alt="logo">
									</router-link>
									<h4 class="pt-5 pt-xl-0 d-none d-sm-block">SAC</h4>
								</div>
								<div class="footer__widget-content d-none d-sm-block">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a :href="'tel:'+ sourceContato.telefone"><i class="far fa-phone-alt font-13 me-1"></i> {{ sourceContato.telefone == null ? '-' : sourceContato.telefone }}</a></li>
											<li><a :href="'mailto:'+ sourceContato.email"><i class="far fa-envelope font-13 me-1"></i> {{ sourceContato.email == null ? '-' : sourceContato.email }}</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Mapa do site -->
						<div class="col d-none d-lg-block">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<h4 class="pt-0">Mapa do site</h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><router-link to="/historia">História</router-link></li>
											<li><router-link to="/categorias">Produtos</router-link></li>
											<li><router-link to="/noticias">Notícias</router-link></li>
											<li><router-link to="/downloads">Downloads</router-link></li>
											<li><router-link to="/trabalheConosco">Trabalhe conosco</router-link></li>
											<li><router-link to="/encontreRevenda">Encontre uma revenda</router-link></li>
											<li><router-link to="/assistenciaTecnica">Assistência Técnica</router-link></li>
											<li><router-link :to="logado.cliente ? '/perfil' : '/login'">Área do revendedor</router-link></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Redes sociais -->
						<div class="col align-self-center align-self-lg-start">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<h4 class="pt-0">Redes sociais</h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a :href="sourceContato.facebook" target="_blank"><i class="fab fa-facebook me-1"></i> Facebook</a></li>
											<li><a :href="sourceContato.instagram" target="_blank"><i class="fab fa-instagram me-1"></i> Instagram</a></li>
											<li><a :href="sourceContato.youtube" target="_blank"><i class="fab fa-youtube me-1"></i> Youtube</a></li>
											<li><a :href="sourceContato.linkedin" target="_blank"><i class="fab fa-linkedin-in me-1"></i> Linkedin</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Categorias -->
						<div class="col d-none d-xxl-block" v-if="clienteData.categorias != null && clienteData.categorias.length > 0">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<h4 class="pt-0">Categorias</h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<categoria v-for="(categoria, index) in clienteData.categorias" :key="index" :categoria="categoria" :page="'Footer'" />
											<li><router-link to="/categorias">Todas categorias</router-link></li>
										</ul>
									</div>
								</div>
							</div>
						</div>

						<!-- Endereco -->
						<div class="col d-none d-md-block">
							<div class="footer__widget">
								<div class="footer__widget-title footer__widget-title-4">
									<a href="https://maps.app.goo.gl/a3s85AudUxKQq85EA" target="_blank">
										<img class="w-100 rounded" src="@/assets/cliente/img/banner/localbanner.jpeg" />
									</a>
									<h4><i class="far fa-map-marker-alt font-14 me-1"></i> <span v-html="sourceContato.enderecoTitulo"></span></h4>
								</div>
								<div class="footer__widget-content">
									<div class="footer__link footer__link-4">
										<ul>
											<li><a href="javascript:;" v-html="sourceContato.enderecoLinhaUm"></a></li>
											<li><a href="javascript:;" v-html="sourceContato.enderecoLinhaDois"></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer__copyright footer__copyright-2 py-3 footer-bg-2">
				<div class="container custom-container">
					<div class="footer__copyright-text footer__copyright-text-2">
						<p>Copyright © <router-link to="/">CCM do Brasil.</router-link> Todos os direitos reservados. By <a href="https://agenciaoneway.com/" target="_blank"> OneWay.</a></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>

import { mapState } from 'vuex'
import categoria from '@/components/home/Categoria.vue'

export default {
	name: 'Footer',
	computed: {
		... mapState({
			clienteData: state => state.clienteData,
			sourceContato: state => state.source.contato,
			logado: state => state.logado
		})
	},
	components: {
      categoria
	}
}

</script>

<style scoped>

img[alt="logo"] {
	width: 120px;
}

</style>