import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // Cliente
   {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
   },
   {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue')
   },
   {
      path: '/perfil',
      name: 'Perfil',
      component: () => import('../views/Perfil.vue')
   },
   {
      path: '/categorias',
      name: 'Categorias',
      component: () => import('../views/Categorias.vue')
   },
   {
      path: '/historia',
      name: 'História',
      component: () => import('../views/Historia.vue')
   },
   {
      path: '/noticias',
      name: 'Noticias',
      component: () => import('../views/Noticias.vue')
   },
   {
      path: '/produtos',
      name: 'Produtos',
      component: () => import('../views/Produtos.vue')
   },
   {
      path: '/produto/:codigo',
      name: 'Produto',
      component: () => import('../views/Produto.vue')
   },
   {
      path: '/downloads',
      name: 'Downloads',
      component: () => import('../views/Downloads.vue')
   },
   {
      path: '/encontreRevenda',
      name: 'Encontre_Revenda',
      component: () => import('../views/EncontreRevenda.vue')
   },
   {
      path: '/assistenciaTecnica',
      name: 'Assistência_Técnica',
      component: () => import('../views/AssistenciaTecnica.vue')
   },
   {
      path: '/sejaRevendedor',
      name: 'Seja_Revendedor',
      component: () => import('../views/SejaRevendedor.vue')
   },
   {
      path: '/trabalheConosco',
      name: 'Trabalhe_Conosco',
      component: () => import('../views/TrabalheConosco.vue')
   },
   {
      path: '/noticia/:id',
      name: 'Noticia',
      component: () => import('../views/Noticia.vue')
   },
   {
      path: '/produtoDivulgacao/:codigo',
      name: 'Produto_Divulgação',
      component: () => import('../views/ProdutoDivulgacao.vue')
   },
   {
      path: '/formulario/:id',
      name: 'Formulário',
      component: () => import('../views/Formulario.vue')
   },
   {
      path: '/produtoComparacao/:codigo',
      name: 'Produto_Comparação',
      component: () => import('../views/ProdutoComparacao.vue')
   },
   // Admin
   {
      path: '/admPaginaInicial',
      name: 'AdmPágina_Inicial',
      component: () => import('../views/admin/PaginaInicial.vue')
   },
   {
      path: '/admBlog',
      name: 'AdmBlog',
      component: () => import('../views/admin/Blog.vue')
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   $(".offcanvas__area").removeClass("opened");
   $(".body-overlay").removeClass("opened");
   $("#app > .wrapper").removeClass("toggled");

   store.dispatch('isRouteAdmin', String(to.path).startsWith('/adm'))
   store.dispatch('saveHistory', to)

   if (to.params.codigo) {
      document.title = 'CCM do Brasil - Cód. '+ to.params.codigo;
   } else {
      document.title = 'CCM do Brasil - '+ String(to.name).replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "");
   }
   
   if (localStorage.ccmPesquisa) {
      if (to.name == 'Produtos') {
         store.dispatch('resgatarPesquisa', JSON.parse(localStorage.ccmPesquisa))
      } else if (to.name != 'Produto') {
         localStorage.removeItem('ccmPesquisa')
      }
   }

   if (to.path.includes('adm')) {
      if (!store.state.logado.admin) {
         next('/')
      } else {
         next()
      }
   } else {
      if (!store.state.logado.cliente && to.name == 'Perfil') {
         next('/')
      } else {
         next()
      }
   }
})

export default router
